import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import VideoCard from 'components/Products/IN-LAN/YoutubeVideoCardINLAN';
import NavButtons from 'components/Products/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-LAN 500 & 500p",
  "path": "/Products/IN-LAN/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "IN-LAN is an intelligent and secure technology that lets you set up a home network easily via your household power grid - without the need of complex and expensive dedicated cabling.",
  "image": "./QI_SearchThumb_Powerline.png",
  "social": "/images/Search/QI_SearchThumb_Powerline.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-LAN_white.webp",
  "chapter": "Products"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='IN-LAN 500 & 500p' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='IN-LAN is an intelligent and secure technology that lets you set up a home network easily via your household power grid - without the need of complex and expensive dedicated cabling.' image='/images/Search/QI_SearchThumb_Powerline.png' twitter='/images/Search/QI_SearchThumb_Powerline.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Produkte/IN-LAN/' locationFR='/fr/Products/IN-LAN/' crumbLabel="Powerline" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "powerline",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#powerline",
        "aria-label": "powerline permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Powerline`}</h1>
    <h2 {...{
      "id": "instar-in-lan-500",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instar-in-lan-500",
        "aria-label": "instar in lan 500 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR IN-LAN 500`}</h2>
    <VideoCard mdxType="VideoCard" />
    <h3 {...{
      "id": "powerline-network-communication",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#powerline-network-communication",
        "aria-label": "powerline network communication permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Powerline Network Communication`}</h3>
    <p><strong parentName="p">{`What is the difference between IN-LAN 500 & IN-LAN 500p?`}</strong></p>
    <p>{`The `}<strong parentName="p">{`P`}</strong>{` in IN-LAN 500p stands for pass-through. Unlike the base model the 500p will block your power outlet but pass through the existing one. Both models offer the same advantages otherwise:`}</p>
    <ul>
      <li parentName="ul">{`Use existing power lines to implement a network with IN-LAN.`}</li>
      <li parentName="ul">{`Very simple plug & play technology. Just plug into the wall socket and you're done.`}</li>
      <li parentName="ul">{`Ultra-fast data transfer up to 500Mbps.`}</li>
      <li parentName="ul">{`Expand your network with for e.g. IP cameras without laying network cables.`}</li>
      <li parentName="ul">{`A very detailed instruction will make the installation very easy.`}</li>
    </ul>
    <h3 {...{
      "id": "in-lan-powerline-communication",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#in-lan-powerline-communication",
        "aria-label": "in lan powerline communication permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-LAN Powerline Communication`}</h3>
    <h4 {...{
      "id": "what-is-in-lan",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#what-is-in-lan",
        "aria-label": "what is in lan permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What is IN-LAN?`}</h4>
    <p>{`IN-LAN is an intelligent and secure technology that lets you set up a home network easily via your household power grid - without the need of complex and expensive dedicated cabling. IN-LAN communication now attains speeds you would expect from other LAN technologies. IN-LAN uses the household power grid to transfer data between computers equipped with suitable adapters and other network components. As a result, any power outlet can be used as a network access point. State-of-the-art technology ensures that the power and data networks do not interfere with one another.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/67523a10be6d37397900c9a52001508f/b4294/IN-LAN500p.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAFvQwwoJA//xAAdEAABBAIDAAAAAAAAAAAAAAACAAEREwMEFCEx/9oACAEBAAEFAheNmxN5xhuIIQ4Ov//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EAB4QAQABAwUBAAAAAAAAAAAAAAEAAgMxEBEhQVGB/9oACAEBAAY/ArquIbd6NwahfJmc3a37P//EABsQAQADAAMBAAAAAAAAAAAAAAEAESExQVHB/9oACAEBAAE/ITZbdb5DjGDPYrDHvKloIKX2Jyp7xP/aAAwDAQACAAMAAAAQ0w//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAbEAEAAwEBAQEAAAAAAAAAAAABABEhMVFxgf/aAAgBAQABPxC/JgEoJVz8Il2ZG1nF8Z7Qmx400ABb10y4gUAAvePYIu3OL4AT/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/67523a10be6d37397900c9a52001508f/e4706/IN-LAN500p.avif 230w", "/en/static/67523a10be6d37397900c9a52001508f/d1af7/IN-LAN500p.avif 460w", "/en/static/67523a10be6d37397900c9a52001508f/56a33/IN-LAN500p.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/67523a10be6d37397900c9a52001508f/a0b58/IN-LAN500p.webp 230w", "/en/static/67523a10be6d37397900c9a52001508f/bc10c/IN-LAN500p.webp 460w", "/en/static/67523a10be6d37397900c9a52001508f/e88ff/IN-LAN500p.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/67523a10be6d37397900c9a52001508f/e83b4/IN-LAN500p.jpg 230w", "/en/static/67523a10be6d37397900c9a52001508f/e41a8/IN-LAN500p.jpg 460w", "/en/static/67523a10be6d37397900c9a52001508f/b4294/IN-LAN500p.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/67523a10be6d37397900c9a52001508f/b4294/IN-LAN500p.jpg",
              "alt": "INSTAR IN-LAN 500 Powerline Adapter",
              "title": "INSTAR IN-LAN 500 Powerline Adapter",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The IN-LAN 500p passes through the power from the used outlet - use the 500p if you don't have enough outlets and don't want one to be blocked by the powering adapter.`}</p>
    <h4 {...{
      "id": "what-does-instar-in-lan-offer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#what-does-instar-in-lan-offer",
        "aria-label": "what does instar in lan offer permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What does INSTAR IN-LAN offer?`}</h4>
    <p>{`IN-LAN offers a PLC transfer rate of up to 500Mbps (effective rate 65Mbps TCP / 90 Mbps UDP) with a range of up to 150m which makes it ideal for applications with high bandwidth requirements, such as IP cameras, HD video streaming, VoIP and fast Internet access. Connecting your IN-LAN adapter to an electrical outlet transforms the home-based electrical system into a fast network with high data throughput with maximum data security using the powerful 128-bit AES encryption. Simply press the Pair Button on the IN-LAN adapter or use the Atheros configuration wizard to set up custom encryption for your network. Whether in the office or at home - connect all your LAN devices without the need of an additional LAN cable.`}</p>
    <h4 {...{
      "id": "how-to-connect-your-in-lan-adaptor-to-the-network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#how-to-connect-your-in-lan-adaptor-to-the-network",
        "aria-label": "how to connect your in lan adaptor to the network permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to connect your IN-LAN adaptor to the network?`}</h4>
    <p>{`Simply plug one IN-LAN adaptor into a power socket next to your broadband router and connect the adaptor to your router via the supplied Ethernet cable. Plug the second IN-LAN unit into an outlet - for example - close to your INSTAR camera and connect the camera to your network using the cameras Ethernet cable.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4b3ebecf2c85a255b7af5ef6e08f073f/8c557/IN-LAN500-Installation.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAABvElEQVQY0yXPvW7aUAAFYL9A+g5Vqw6kapd0qNSo3aOqv1v6OEjNQkUHw2B3bRwiTGonDsglkVBpLYKv/zC+9uX62rpGYAKGmQFVmG880pHOYbrdruu6tm07OQCAYRiu65qmCQDQdR0A4DiO67qOM7Bt27Is0zTtHGNZFiEEYxzmIITD4ZDjOE3TKKUQQkKILMuCIMRxjDFOkiRNp1EUYYwZAEAYhqMcxngwGMxmsyzL5vM5xtjzPELIYrFYrVa7giiKxWJR13VCCGMYRhAEvu8HQYAQsm07TdPNZkMpRQjt8vV6vVwux+NxvV4/fPVy/8nDt0dHCCHGcZxOp+N5XpIklFJCCIQQoaDf7++2+L6PUGAYxmg0UlX1xcFB4fGj4+MvlNLt51qt1my1bm5uLy+vNO0f9H3lSu71emEYBlvob/ePoiiUJr+ki8M3r/f2Hnz6+CGKom1ZVdunDYk/rf08r7euW/f3M0mS7+760+k0juPJZOJ53plwni2yUrn87vP7QmH/2dPnuq4zoihKknQmCN9Kpe/lMsuyHMdVKlVFuW63279zqqqybIXn+Wq1+vXkhOd/NBoXzWbzPwmYalPhd/a4AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4b3ebecf2c85a255b7af5ef6e08f073f/e4706/IN-LAN500-Installation.avif 230w", "/en/static/4b3ebecf2c85a255b7af5ef6e08f073f/d1af7/IN-LAN500-Installation.avif 460w", "/en/static/4b3ebecf2c85a255b7af5ef6e08f073f/70e80/IN-LAN500-Installation.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4b3ebecf2c85a255b7af5ef6e08f073f/a0b58/IN-LAN500-Installation.webp 230w", "/en/static/4b3ebecf2c85a255b7af5ef6e08f073f/bc10c/IN-LAN500-Installation.webp 460w", "/en/static/4b3ebecf2c85a255b7af5ef6e08f073f/426ac/IN-LAN500-Installation.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4b3ebecf2c85a255b7af5ef6e08f073f/81c8e/IN-LAN500-Installation.png 230w", "/en/static/4b3ebecf2c85a255b7af5ef6e08f073f/08a84/IN-LAN500-Installation.png 460w", "/en/static/4b3ebecf2c85a255b7af5ef6e08f073f/8c557/IN-LAN500-Installation.png 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4b3ebecf2c85a255b7af5ef6e08f073f/8c557/IN-LAN500-Installation.png",
              "alt": "INSTAR IN-LAN 500 Powerline Adapter",
              "title": "INSTAR IN-LAN 500 Powerline Adapter",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "powerline-networks",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#powerline-networks",
        "aria-label": "powerline networks permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Powerline Networks`}</h3>
    <h4 {...{
      "id": "how-to-build-a-public-network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#how-to-build-a-public-network",
        "aria-label": "how to build a public network permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to build a public network?`}</h4>
    <ol>
      <li parentName="ol">{`Press the pairing button for 5 seconds until the signal lights flash once. The Adapter is now ready to negotiate a pairing password with another adaptor.`}</li>
      <li parentName="ol">{`Press the pair button for 1 second, and then release it. The power LED should start to flash - signaling that it is waiting to be paired with another adaptor. The pairing mode is `}<strong parentName="li">{`active for 2 Minutes`}</strong>{`. Do the same to the other IN-LAN device, press one second and release, and wait for the two devices to pair successfully.`}</li>
      <li parentName="ol">{`The pairing was successful when the power LED changes from flashing to constant on, the data LED stays constant on.`}</li>
    </ol>
    <p>{`To exclude one IN-LAN adaptor from an existing network, press its Pairing Button for more than 10 seconds. The device will be assigned with a new random encryption key and will no longer be able to access the network. To integrate the adaptor into a different IN-LAN adaptor network, follow the steps described above.`}</p>
    <p><strong parentName="p">{`No Adaptors Found?`}</strong>{`
Please make sure to connect your PC or Laptop by LAN cable before using the Power Packet Utility Tool. The powerline device discovery will fail if you are using WiFi!`}</p>
    <h3 {...{
      "id": "how-to-build-a-private-network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-to-build-a-private-network",
        "aria-label": "how to build a private network permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to build a private network?`}</h3>
    <p><strong parentName="p">{`[1]`}</strong>{`
Please plug in one IN-LAN Adapter to the power socket next to your router and connect it with the supplied network cable with a free Ethernet port. If you install and open the Power Packet Utility (which is included on the CD of your IN-LAN adapter) you should be able to see the IN-LAN adapter as a Local Device(s) together with the MAC address of the adapter. Please choose the adapter and select the tab Privacy.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "615px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9e80a8ae1bc6277b880f165a2cf25af4/f6b72/IN-Lan_Power_Packet_Utility_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC2UlEQVQ4y42Ta4vjdBjF+ylU/BSC+ELwg/gRVFzZN+KOsirjyiLim1XwxaLIiAi77gq6i+xcwLGTtuk9zaVN8k/SSZp22qSZ2Us7dXr5SdOZgrDiPHA45/x5OJwXzz/z6od9Xns/5PWNLq9ci3np3WNevpLw4jvx8/H2v/HCWzFvfPKYNz8VXLuyRcZRJIx7P9J6eJe6VkbR6ym8QOB3Xdodgd8RBKFDEAo6XSfVnXP4gY0fWPRCk+09mYxdfYS6/RO29CuuuoeoP8JTdxm4eU46ZZ72FXqBgmfL+G4Z0crRFkVcq5C+Dfs6yaDFk8TkwW6OzPdf3eaj61vcuHGHL7+4z+bmXTY2ttj44Ds2P7vD1zd/JmzKGM0CTS1H260S+g3CQKUbqPR7BoNek+PI5PdtiUxLrlOQXPJZm1zW5mB/BVlykLIOpb90uk4VYZcQZjENSyKL4cBMeYnoqMVJfB4YR4Jh7HKSeCSRw+OkzdmkTxI76dswdrDNIi1dwmrmOXQrREfNFKGvYLcK9Doqx1FrFdio7VPI7aDUsgizQkuXqVX2UapZHKuasqpI9HsWZrNMoy5haDKqkkNr5HHtGp7TIDpaNsyRmYyfMJvNmc1mXMzZ2dnaz+fz1F/wZDJhOp2meonVzgKY8nBXJjP9e8R/zYL/n8Vicb43WwUuG87nqyYXWCzmq8VLYbW7bni5HpebNLCc3aFekAg7hwRuE9uokAxDTscxp6OY0dNByqfj4dqPRxHjZ9Faj55FTEYDHuzkybx3/Ruubv7A7fsyn3/7Gx/fukfg6/heNT2V5UkIU0718kQGPQNPlPHbtVS7ooTv1UgGxupsCorKTr7Gbq7En0WF/WKNo65G4FXwxPKQa+mX84TMoVui11FSH7QrdIM6vlfG9ypEvQZ/7OXICEvFtjSEpWObGpapoWoNNF1DN5ZaXeslX3hNV1OtGzoFWeZAyrL1ywH/AIw3IvDxP0cCAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9e80a8ae1bc6277b880f165a2cf25af4/e4706/IN-Lan_Power_Packet_Utility_01.avif 230w", "/en/static/9e80a8ae1bc6277b880f165a2cf25af4/d1af7/IN-Lan_Power_Packet_Utility_01.avif 460w", "/en/static/9e80a8ae1bc6277b880f165a2cf25af4/3b39a/IN-Lan_Power_Packet_Utility_01.avif 615w"],
              "sizes": "(max-width: 615px) 100vw, 615px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9e80a8ae1bc6277b880f165a2cf25af4/a0b58/IN-Lan_Power_Packet_Utility_01.webp 230w", "/en/static/9e80a8ae1bc6277b880f165a2cf25af4/bc10c/IN-Lan_Power_Packet_Utility_01.webp 460w", "/en/static/9e80a8ae1bc6277b880f165a2cf25af4/26ec8/IN-Lan_Power_Packet_Utility_01.webp 615w"],
              "sizes": "(max-width: 615px) 100vw, 615px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9e80a8ae1bc6277b880f165a2cf25af4/81c8e/IN-Lan_Power_Packet_Utility_01.png 230w", "/en/static/9e80a8ae1bc6277b880f165a2cf25af4/08a84/IN-Lan_Power_Packet_Utility_01.png 460w", "/en/static/9e80a8ae1bc6277b880f165a2cf25af4/f6b72/IN-Lan_Power_Packet_Utility_01.png 615w"],
              "sizes": "(max-width: 615px) 100vw, 615px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9e80a8ae1bc6277b880f165a2cf25af4/f6b72/IN-Lan_Power_Packet_Utility_01.png",
              "alt": "IN-LAN 500 Power Packet Utility",
              "title": "IN-LAN 500 Power Packet Utility",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`[2]`}</strong>{`
In Private Network Name you can now see the network name / encryption with which the adapters are communicating between each other.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "616px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fe3b4871de43023d99932829f8b7283f/40040/IN-Lan_Power_Packet_Utility_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAACwUlEQVQ4y52SS2/jVBTHs0NixJIdOyTY8AH4NojRsBjNAg1ix7eBBSyA6QiVaYuGljYPJ3Ga+HXvtX1tJ04cO07SlMf+h3zTpoBmxeKnc3zvuf/zcuv9z2d88HzJh88LPvqy4N2nKx49qXnnSc3bj6s382nFo4bHFW99UvHes4qPv9rw2RdntM4thygWjN0xY2dM/3pCf+IxdHyETpFJZhDJP3yd4kf6gIg1UaKRvkfLVy6LzELLS9KwTRZekkUdYtVmlgyYacswTwfkuk+e9KnmY/7YKn7fSMPtWvLnjSKfBbRUJHDOjvjpu+85/fGIkx9eIroXlPY5c2VRLAKK3GM+dZllDnnmUOQ+q1KxWkpDtZRs65A08WiFkY9//JIXXx9z/O3PHH1zjPf6nLp9iui9YjqdEAZdBtYp/d4JQ+sMq3uCDDpGZF01ooKbtSJLPVoiltwUE5ZRl1xesQg7TEUb7bdJtU0SDffEQ3Q0II2H5NmY+XRCXcoDD4KR4K9bTVUpdGwTxzaJttHxkEzbRiCJBuiwjxJdYzcreajunpt1uBeUsSRPh+hwwDQZGZrg3SY23G71v2jOGoHNKjQ0sXUjWN9VGESCdelT5C7Cu0J4bWJloYKu8QP3Ct+5NNab/GYqrIrAjCCNbVPIfOo8VBiEgtuNMtnqUhiqwqdceISyhwo6RNJC+h2ziGYM1TIwws0oponNYvZfwbWiXPgmuAmcXJ8zGvyCM75gPPoVq/sKu3/GoHdK4F6y22qyZEQoeuZdtRRsDy2Hgl29X32TqaHZ4Cy7Nn7T1rX92rTtji+IlGW2mt4lb4po/tODoIgku7WiLtVh0PfD3tvosIyG5ntdKXPf+PulKHabiCz1aXnSZVO6LOcu5eINzJs7h3Lu3NmH83uqwjP3Op7QGvkBy4VkNpPk/4dckmUBzqTL9cTmbwHMEJhh4TZPAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fe3b4871de43023d99932829f8b7283f/e4706/IN-Lan_Power_Packet_Utility_02.avif 230w", "/en/static/fe3b4871de43023d99932829f8b7283f/d1af7/IN-Lan_Power_Packet_Utility_02.avif 460w", "/en/static/fe3b4871de43023d99932829f8b7283f/4278f/IN-Lan_Power_Packet_Utility_02.avif 616w"],
              "sizes": "(max-width: 616px) 100vw, 616px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fe3b4871de43023d99932829f8b7283f/a0b58/IN-Lan_Power_Packet_Utility_02.webp 230w", "/en/static/fe3b4871de43023d99932829f8b7283f/bc10c/IN-Lan_Power_Packet_Utility_02.webp 460w", "/en/static/fe3b4871de43023d99932829f8b7283f/26c8a/IN-Lan_Power_Packet_Utility_02.webp 616w"],
              "sizes": "(max-width: 616px) 100vw, 616px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fe3b4871de43023d99932829f8b7283f/81c8e/IN-Lan_Power_Packet_Utility_02.png 230w", "/en/static/fe3b4871de43023d99932829f8b7283f/08a84/IN-Lan_Power_Packet_Utility_02.png 460w", "/en/static/fe3b4871de43023d99932829f8b7283f/40040/IN-Lan_Power_Packet_Utility_02.png 616w"],
              "sizes": "(max-width: 616px) 100vw, 616px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fe3b4871de43023d99932829f8b7283f/40040/IN-Lan_Power_Packet_Utility_02.png",
              "alt": "IN-LAN 500 Power Packet Utility",
              "title": "IN-LAN 500 Power Packet Utility",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`[3]`}</strong>{`
Replace the Private Network Name with a password you want to use. Please notice that this password will be the one, with which the adapters are communicating. If you buy a new adapter, you need to set the same password in order to integrate it into the existing IN-LAN network. Next please click Set Local Device Only to submit the password and sent it to the IN-LAN adapter.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "613px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ff829bdd0e0887cf37514fbdc15e5c21/5754a/IN-Lan_Power_Packet_Utility_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAACrklEQVQ4y52Sy24jRRRA/QcE8RMsZsOCD+EfGJg1IJDgd9gEgYQgIo6tEZNM2u22nfjR765+2+63H+IHDqpyJpOAxILF0b2lrj51697qvfi24sU3BZ98V/Lx1zVnX+746FXH2RcNZy//mw9fNnzwec2n3x/47IeAr179SC+NTSKxwvfuse07bNdkac6J04B8G5NtJNEDpzzJQ5JcPMSQLBeUW5/hcEIvT2Y4joZl3WCu3uB7Gp6rEQY6qTCIfJ0snJCGxiP72uHQuhwaV8V94/LX3udiMKZX+GMWF+e4N5eE2oBiodEtbinElM3aolhb5OmSNJ6TxQvyZEm5dahL90RxiofO5/f+iF7j6fi/nZPdDqkn1xTGNdV1n3D6GtcbE4spM2PARL/C0PuKqTFgm6/YNT5N6SqOnc+FFLalzTq+I3JHCPuW0NGIPJ0kvCON7ojFjCy+J1H5lE22VFRb61H2TCiTfReSJXPicKYIg4n6+R1y7TsjPGfENl+ybwPa2ntP9URY5AsCb0wiZWJKXdgcdyGHTqj4T7rGpytddqXDvnTYVR5N5T0IdXpNYVFuLFXFavGGwNUVrqVhm2+xljfYq7fYqxtsW6P0dNLBT4jXvxAMfya+OqcWU457capwV7unU2uPpnRUhfIA2T8pktVLuWfL5zSmjmYURp9kfMl6NmRj9KmTOcd9cBJ2laNkUvCuSjnFmTFkef8nY+0SY9RnMr5iovcp5DBage3opOmSuvGpiic9lELZ1GJtqqdQrFcqyubLXFYpr+1at6oFal+2JPInxMGEdTKn3NjvhfLKcuxyWl3t/4t9K57RVqfJ7ppA0apWeRx3welht+WKamtSb1ePVA+ofPOcp9/VnsKk2pi0pckfA51eFNoEgU0g/h9CONzPp8xmGue/6vwNi4gOySktxe4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ff829bdd0e0887cf37514fbdc15e5c21/e4706/IN-Lan_Power_Packet_Utility_03.avif 230w", "/en/static/ff829bdd0e0887cf37514fbdc15e5c21/d1af7/IN-Lan_Power_Packet_Utility_03.avif 460w", "/en/static/ff829bdd0e0887cf37514fbdc15e5c21/9aa36/IN-Lan_Power_Packet_Utility_03.avif 613w"],
              "sizes": "(max-width: 613px) 100vw, 613px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ff829bdd0e0887cf37514fbdc15e5c21/a0b58/IN-Lan_Power_Packet_Utility_03.webp 230w", "/en/static/ff829bdd0e0887cf37514fbdc15e5c21/bc10c/IN-Lan_Power_Packet_Utility_03.webp 460w", "/en/static/ff829bdd0e0887cf37514fbdc15e5c21/0abb1/IN-Lan_Power_Packet_Utility_03.webp 613w"],
              "sizes": "(max-width: 613px) 100vw, 613px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ff829bdd0e0887cf37514fbdc15e5c21/81c8e/IN-Lan_Power_Packet_Utility_03.png 230w", "/en/static/ff829bdd0e0887cf37514fbdc15e5c21/08a84/IN-Lan_Power_Packet_Utility_03.png 460w", "/en/static/ff829bdd0e0887cf37514fbdc15e5c21/5754a/IN-Lan_Power_Packet_Utility_03.png 613w"],
              "sizes": "(max-width: 613px) 100vw, 613px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ff829bdd0e0887cf37514fbdc15e5c21/5754a/IN-Lan_Power_Packet_Utility_03.png",
              "alt": "IN-LAN 500 Power Packet Utility",
              "title": "IN-LAN 500 Power Packet Utility",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`[4]`}</strong>{`
In the upcoming window please click OK. This message tells you that the password was set successfully.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "614px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/59f37f716c0ed83846d12a8fad3eb0e6/e9131/IN-Lan_Power_Packet_Utility_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAACuklEQVQ4y52SyY4cRRCG+wW54XeAJ0BcLEscAKGxLBkJ+4BBIIQPGEvYLGNZeNzbdFdv1V1ZS1bW2tW1d4/HB84fqpyhbSHkA4dPkRlV+iMj/uh9+sOWz3/MNF883PHJd1tufZty65uUm1/H3HzwL/4zl/DRVxl37k7peZ7FLnMJlInyTXxp4vtrwsCirkOqMqCqruP1uSwCitw/knexUES+Qy+NLZQ7QqzPsMw/dfTsAa7oE/pTQjnRxMGMSBk6l29NLlqPQ+0eeX2QxKGg11UQmyGrxQDPmaPkkqpQlLnHLl1TZIJ8a5HGJmm00rG716VLc01dOLpApCx6xU4SqhWBEjR1SlXEVGVCnklsa0AUzLE3A6bjPzAmpzp2uGLEoZG01VuCgUVvl0mq3CKNlihvipJTAt8g8g3ScEGk5iThgiRcHs/5dqNp3nrlUbCtPP66lBxqiyKZUqYG9dYgiRdEsalnFqkZvjvBs0eEvsG+9o6v+4ej4O1HER/cz/jw/o4bd2rev13x3smBj78UrPpjXl/GvNorLg+B5qJVWmBfSy3a0VTeG8EHv0WcPE6599Tn5HGo+exRzPc/z3j+5BQhug3o41hDLPMloZpR7AShPyOQBkoaZEnnurwS3Lg+XmBzWczYR30O8YCLbEyVGZwbE6Qz1nj2GEcM9ayr3NZj6Oju3TyPgrO1pK4k9fIlyYtfSE5/otyMaNqI4eic+fQZ4+GvzKbPmIx+1853rXcvlM455U7oAofmuuXF2sF2Bc7CwBqcYfVfYK/mOifsDXEwZ7M6wxVD3XYgpzSlo9epa1+7nllvBMtcsq8cmlrSNj5t69PWHm3p6p8Oja9N6czo6O7dZlwZ4l+ZUnq82vtEgaC3TQR1vqHcvYNs/c7vVS50DKRJL1AOWeKQxv+PbeIQhQJzOWS5mPE3ArIZG02+tZwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/59f37f716c0ed83846d12a8fad3eb0e6/e4706/IN-Lan_Power_Packet_Utility_04.avif 230w", "/en/static/59f37f716c0ed83846d12a8fad3eb0e6/d1af7/IN-Lan_Power_Packet_Utility_04.avif 460w", "/en/static/59f37f716c0ed83846d12a8fad3eb0e6/5aad3/IN-Lan_Power_Packet_Utility_04.avif 614w"],
              "sizes": "(max-width: 614px) 100vw, 614px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/59f37f716c0ed83846d12a8fad3eb0e6/a0b58/IN-Lan_Power_Packet_Utility_04.webp 230w", "/en/static/59f37f716c0ed83846d12a8fad3eb0e6/bc10c/IN-Lan_Power_Packet_Utility_04.webp 460w", "/en/static/59f37f716c0ed83846d12a8fad3eb0e6/5316f/IN-Lan_Power_Packet_Utility_04.webp 614w"],
              "sizes": "(max-width: 614px) 100vw, 614px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/59f37f716c0ed83846d12a8fad3eb0e6/81c8e/IN-Lan_Power_Packet_Utility_04.png 230w", "/en/static/59f37f716c0ed83846d12a8fad3eb0e6/08a84/IN-Lan_Power_Packet_Utility_04.png 460w", "/en/static/59f37f716c0ed83846d12a8fad3eb0e6/e9131/IN-Lan_Power_Packet_Utility_04.png 614w"],
              "sizes": "(max-width: 614px) 100vw, 614px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/59f37f716c0ed83846d12a8fad3eb0e6/e9131/IN-Lan_Power_Packet_Utility_04.png",
              "alt": "IN-LAN 500 Power Packet Utility",
              "title": "IN-LAN 500 Power Packet Utility",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`[5]`}</strong>{`
Repeat this step for all existing IN-LAN Adapters. This way all adapters get set to the same private network in order to communicate with each other. After you have set the second adapter, as shown above, you can plug it in to a power socket near the device you want to connect with your network. The IN-LAN adapter should then be shown in the list together with the signal strength. (see below)`}</p>
    <p><strong parentName="p">{`Important:`}</strong>{` We recommend to always test the connection between the adapters by connecting them to power outlets near each other. If the connection is ok you can connect one adapter next to the device, you want to use it. This way you can make sure that the adapters are working correctly. If the adapter does not work at the final installation place, either the quality of the power line is not ok or the power line might have a lot of noise which disturbs the signal of the IN-LAN adapters - e.g. fuse boxes or heavy machinery connected to a power outlet in the same grid can make it impossible to establish a powering connection.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "615px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4fd047ba7a67872049c3d557bb5e89a6/f6b72/IN-Lan_Power_Packet_Utility_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAADLklEQVQ4y63Sy28bVRTH8dkjUdZs2LGBBX8A/wdrVBQJARKLwAKpUhddFKkSCyQEKiAeVUTaRAkUSFy/mjTE8WPsGXvseb88ju3M2E6c4MRxan+Rx1kiddMjffQ799yzudIV3vy0yVvLR7z9WZd3Pu/w+sc9biz1eW2px6sfhP/v5sKNmyGvvB/yxich794a8OFyGmG/VEHXKyj1MjVFpCSVEKsycqOG5duYnoXlWThNO+bOBQ5uc8HxbRzPxA9MbF1BqMhFbCWFVk3hajt4c2qWrpfjpFth1K9xGtVpuSItT6TpFHHNA5p2Ac/K0wkqnEQaw75G068jpHIl7v6U4fb9Pe4+ELn3m8St7w/44pun3L7/jDu/lHmSU+gEEnJlF9ss4TsVWr5M4Em0gxphR6UfarhODUFUNErGkLR8RloakqoM2RaPScunZKtnbIun1O0WTbuI3sjhGEXCToNBqNM/0hhEOr04NTxXQaibOuNRm7NhM3YycJleRfFseOwxOm0RHhmo6gG6XsAwCnS7Kv2eSS8y8H2JZlCjH+m4dhWhkE2w+/Brco9XqGU2ULKbMSm5Ri29gbrzB/u//4q1v4VfTMUzbfcxSmaxIyXX0TIbdCwR264j7K1+R+Lbj3Cf/k1UTDEoZ+MMC0l6YpoTaYf8wx9wMpscFZJoiUd0cts42U3UrVW05Drt5CMYhISDHoKq6zCbwGTCbDxmOr5gdjmOzfs5nl/B5DKecTVZ5ORyMb+aMB2PmVcYHSE0LDs+PJ/OmEFsep0zFjV7gen1Xhh2ETTb4WVVFIUIoixRyGzhWDrtwCOwa7h2g/NRj4tRP87zi2PGl0PGk2Hcj/6N4rt5ns+Neowv+hy2HIS17We8t3SHr35O8uNGjuUvV1j9M8Owp2GoOXy3Qrv0BG1rBfWvB7Tz23Q7DVyzSPdQiT+2a5WIOgquM/82co3UgcTWbpF0rkziHwlJkQkPq1j6Aa5d4rCcxMmuY2fWOCwlaDUlHCNPy68QuCKuVaTll3FtGSEvKXRbKoGvXVPjJxtGHctWsawGpqtj+VZs3ptmHctS4zStBrpRJ1/YI5cv8B8nGBmpJ1y5eQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4fd047ba7a67872049c3d557bb5e89a6/e4706/IN-Lan_Power_Packet_Utility_05.avif 230w", "/en/static/4fd047ba7a67872049c3d557bb5e89a6/d1af7/IN-Lan_Power_Packet_Utility_05.avif 460w", "/en/static/4fd047ba7a67872049c3d557bb5e89a6/3b39a/IN-Lan_Power_Packet_Utility_05.avif 615w"],
              "sizes": "(max-width: 615px) 100vw, 615px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4fd047ba7a67872049c3d557bb5e89a6/a0b58/IN-Lan_Power_Packet_Utility_05.webp 230w", "/en/static/4fd047ba7a67872049c3d557bb5e89a6/bc10c/IN-Lan_Power_Packet_Utility_05.webp 460w", "/en/static/4fd047ba7a67872049c3d557bb5e89a6/26ec8/IN-Lan_Power_Packet_Utility_05.webp 615w"],
              "sizes": "(max-width: 615px) 100vw, 615px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4fd047ba7a67872049c3d557bb5e89a6/81c8e/IN-Lan_Power_Packet_Utility_05.png 230w", "/en/static/4fd047ba7a67872049c3d557bb5e89a6/08a84/IN-Lan_Power_Packet_Utility_05.png 460w", "/en/static/4fd047ba7a67872049c3d557bb5e89a6/f6b72/IN-Lan_Power_Packet_Utility_05.png 615w"],
              "sizes": "(max-width: 615px) 100vw, 615px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4fd047ba7a67872049c3d557bb5e89a6/f6b72/IN-Lan_Power_Packet_Utility_05.png",
              "alt": "IN-LAN 500 Power Packet Utility",
              "title": "IN-LAN 500 Power Packet Utility",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`No adapters found?`}</strong>{`
Please make sure you connect your PC or laptop with a LAN cable before using the Power Packet Utility Tool. The Powerline device detection fails if you use WiFi!`}</p>
    <h3 {...{
      "id": "how-to-change-the-password-for-a-private-network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-to-change-the-password-for-a-private-network",
        "aria-label": "how to change the password for a private network permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to change the password for a private network?`}</h3>
    <p><strong parentName="p">{`[1]`}</strong>{`
This step shows you how to change the encryption for all adapters at the same time in case you want to change it for your existing network. Therefore, please select one of the connected adapters and select Enter Password. Type in the hardware key that you can find on your IN-LAN adapter or on the package of each adapter. It is important that you check if the MAC address is matching the adapters address. After typing in the key (case sensitive) you can submit it. If everything is correct it should accept the new password.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "613px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a2e46d12bd6c798f2956913c920f8e0e/5754a/IN-Lan_Power_Packet_Utility_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "115.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEd0lEQVQ4y6WT2W9UZRjGD157ZVz+AhJiwp1EISSSGAhgKIlX3mmIMS5xAUUJMUZB5YJIDBZLUTHcIJhqhLJ0oZ19OltnzsxZZz2zr+3Q0oXO0vmZc1oCVxbjm/zyvc+Xk+d8eb/nE4S+82zIq/08/0GE/SeLvPJVgT0n8rx0vMjmwxU2f1Riy5EqWz9rsOXNNMJGZk8cPI+w5xz9Q25qBZGcESMluUkrXhKSGzVsJxFzM1tTCUeVxzTc3c+F61Ms3tWZkVwU7TfJuUYpusfIu8dYEL3c1z2I8uMa7h3gzOUJnHeucu3yILJ9mIYeQHbcQHHdQpocxhAnicrqxobCgQGEvl945+vfeOuNQxx6+0N+GjzHwkIWh3OYqakRHM4biOE7BILTGxtu6htAODDI+99d4djx03zxzc9c+eMv5mfjRKdthHxjBLwjpDQPvkAIYdPBQf6VvgE2vXaJ1w+f5rmnnuTpZ57l1LdfsjhvMOUdwe8fR1G8xHUvU/4ggrDvLMLeRzD1vh/X2G9yFmH/ed498Su7d+3ghW3b+OHMKZaWCpQVD0XH31Q8N1mIexAVDWHn0Ule/txuseuYnZ1HbWw/Msn2T2y8eHiCHUcm2PreOEM2mZX5OLWyQrOu0ZxNMJcLU/bfJuu6zr2Uh5iiIEz4DJzBNRyBDGOeJGOeDKPuFPZgHnswx6Q/jysYJ2coGIaGYagYGZVsVkeSp5kO+zAMGY8vimCkwiQVG5rsRJMd6LKDhOqimA0x11Bp1hVrzWU10mmdVEollVRIJCSSCZlMxtzXyOd0PF4RQdGijNlH8Hluocoe4pqPaNhOKDBOODRJNhOmUlJJ6lPEIpMkda9FNh0kFZ+iWozSbOgszMWRoiKCrEoEYzLVUopKOU29mqU5W6RazlDMJ6hWDObv5sgbEZK6n3xWpFZWmanpNKpr1Csac7NxxHAEoVRMQXeJTmeFdnuZVmuZbmeF1W7L0p32CnPNAkrMia6Yp/NZZs1Ggtl6nHpFtQzNXIqRCEI6JePzjuJx3SAathEJTaArHkL+MSLTE0hRB37vCJlkkHQigCQ60GQPCc2HHHVa4zH7WjlGeDqMEAqN8ufQSVTZR7kgU6/oFHIxsukI1bJGrazhsA1jXl4hGyMybSeTnEaKuoiEbKiSm1olRae9iCxpCOZ1z8/labdW6HZbFr3VDr1el263bbG4cI92+77Vm6Pp9TrrI1rh/vIi3W4Hs1Qlac7Q4EH1eiY9i/9Sq+vfK0oCoVjM8H/rwf+tE+ZzCQpGglq5SHOmTr2Yol4t0Gkv0W4tPsTU63ut9b3WysO1t7q8NkNvIMDHn57i4tVx/hoL8v2FIW5POpibiVMqSNbbrZYUygWJSnFNmzGpFGUaVc3KpNnfndGJRkQEUZbov2rn0nUvv9/2c/GaH2dQtF6AGLYRV71WZKIRu6WNdMjSZnQKWZFU3I8me6mW1mNTKmgsNjXm6irzDY17MyrNmmoFdrauW6cwMftHtRlu8xtzLeUl6zmGgiH+AWsNvvJtIKHZAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a2e46d12bd6c798f2956913c920f8e0e/e4706/IN-Lan_Power_Packet_Utility_06.avif 230w", "/en/static/a2e46d12bd6c798f2956913c920f8e0e/d1af7/IN-Lan_Power_Packet_Utility_06.avif 460w", "/en/static/a2e46d12bd6c798f2956913c920f8e0e/9aa36/IN-Lan_Power_Packet_Utility_06.avif 613w"],
              "sizes": "(max-width: 613px) 100vw, 613px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a2e46d12bd6c798f2956913c920f8e0e/a0b58/IN-Lan_Power_Packet_Utility_06.webp 230w", "/en/static/a2e46d12bd6c798f2956913c920f8e0e/bc10c/IN-Lan_Power_Packet_Utility_06.webp 460w", "/en/static/a2e46d12bd6c798f2956913c920f8e0e/0abb1/IN-Lan_Power_Packet_Utility_06.webp 613w"],
              "sizes": "(max-width: 613px) 100vw, 613px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a2e46d12bd6c798f2956913c920f8e0e/81c8e/IN-Lan_Power_Packet_Utility_06.png 230w", "/en/static/a2e46d12bd6c798f2956913c920f8e0e/08a84/IN-Lan_Power_Packet_Utility_06.png 460w", "/en/static/a2e46d12bd6c798f2956913c920f8e0e/5754a/IN-Lan_Power_Packet_Utility_06.png 613w"],
              "sizes": "(max-width: 613px) 100vw, 613px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a2e46d12bd6c798f2956913c920f8e0e/5754a/IN-Lan_Power_Packet_Utility_06.png",
              "alt": "IN-LAN 500 Power Packet Utility",
              "title": "IN-LAN 500 Power Packet Utility",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`[2]`}</strong>{`
Select OK and the hardware key will be sent.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "613px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b364d218dee0d1e858a37d1a1df4c66a/5754a/IN-Lan_Power_Packet_Utility_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "116.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAAAsTAAALEwEAmpwYAAAErklEQVQ4y42U229UVRSHDz4Z5X/ySRCRYvTF+KK+qFEUFC8BYgImGjQaQ6xQaABBvIAYhFKQQlvaTmc6l545c+6XmXOZ+62ddij0gtPPnINEeBFW8uW31s7OL2tnrWxB6Bvg/9iwfQDhuX529af45ZrPicsuJ4byDFxwOHTW4rtfdb4/Z3P0oseh0w7CowyfCA03/cDPV6bo1kUalRwVL0WtmKHspfCdONUgw1JHp+Qbj2n4bD9nrs5wp2PTbhUoFR081yTwrUgXF1p0Oz6OrT/acMP2IwhbjtD/619c+O0wp072I4mTLMx76Eocy0ih5Kax9Dh55zEMhb4jCH2D7D74I++8uYMdO/dw7OhhuvMOoyPnuTl2gdHrfyCmRzB1+TE6DA23HWP31z+z64MD7Pr0W3765VxkaGpxNHkKOTuB6yTRVAkhnOKGvn95ML/PtsNsePkUr390kI1PPsFTT2/kwP493O76xGPDJGJXUOUpdGUCTZEQhC3f8xDP9yNs7f9Pt4ZnA+z77idee/UltvW9wODRQ9xacKmVVaollUbVoNO2cPMmwrb9cbYfSES8+HmC5z+Ls3nvNM/tm2bTnhib98Z45uMYf45mMHI3EDOjFOwkQSHDfMui5IvkrSTNmoJXMBCm0nlmRIeUVGAmmyeWtpnOhOowqxZJywFpuYgkmzimhG3K+K5O4BkUfQtdFZHEGXxXRZZ1hKKXw9HG0eRxTHUSU52IKPsZOi2dxTmDhbZOraxRLRtUSjqlQKUcaJR8lXrFpFGzmWvaZMUcgqxJXB27SmYmHHuCvJ1CU2LMpsLnjeHmM1TLOpaRIJsZjfYtxHVS2EaCailHu2GwOG+Ry2YRdFNFMW2adZ96LaDZKNKZq0ZaKbs06kW6CyUCV8SxUhQ9iXbDZK5pMd+yI1p1k4U5CykrIVQqLqsrSyzd7nJraZHFxQ7LK7dZXr7F7aUOK8u3aDUK6MpU1KVjJSPD0CjUSlGmUdXozttIYYdBYRYpcQ41fYmKM07ZHqXpTWIq11GVCdTcBMnEtahDvyCi5CbR5Fi01Io0Ee2gY6Zo1jSyYhbh1BWRV764wScnHXaeCPjwVJm3jhX5YjCNnEzSahWYGBvCdURKvoKcnSJwc+hKgpw4iaZM06y73F27g6KYCGdGPPadqfLlWY/3BwPeHfB5e7DGN8fTZOM6YayuLNPr/c36ei9SWI80ZG1tlV6vF93TNQdBMWxmFY1WLUu7lqFZTdMuz1AvpckHdR6M9fX1h/R+9Hr3ak2zESTNptEu0LFnKI/+QWXkd9panFbLxPWqPE7c9486TM4qDF8cYvraNRJDw9w4fZyJ4SF0y8DJ+6ytdllZXmBtpRsR5iGrK4ss3wnzUBfp3e2iKjpCLJnmjfe+4tuTIxy/lGH/wDBDYwk6LZvAk6iW5OgTCLwsgZuN8kZVj9alWTOoVzTKgcx8S0cKp2zYGudvJLl8M8VILMPF8QyzSo52LYehTuLlk5S8DKY2GVEJxKguWHFqZQm/kKJgJWjXZWRJRNANg2rRpOSZFD2TSmBGv4ZlauQdE9s2sG09yu/VOral49gGlqVFqqoyyeQ04zcz/AOlZbYay/Ez7AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b364d218dee0d1e858a37d1a1df4c66a/e4706/IN-Lan_Power_Packet_Utility_07.avif 230w", "/en/static/b364d218dee0d1e858a37d1a1df4c66a/d1af7/IN-Lan_Power_Packet_Utility_07.avif 460w", "/en/static/b364d218dee0d1e858a37d1a1df4c66a/9aa36/IN-Lan_Power_Packet_Utility_07.avif 613w"],
              "sizes": "(max-width: 613px) 100vw, 613px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b364d218dee0d1e858a37d1a1df4c66a/a0b58/IN-Lan_Power_Packet_Utility_07.webp 230w", "/en/static/b364d218dee0d1e858a37d1a1df4c66a/bc10c/IN-Lan_Power_Packet_Utility_07.webp 460w", "/en/static/b364d218dee0d1e858a37d1a1df4c66a/0abb1/IN-Lan_Power_Packet_Utility_07.webp 613w"],
              "sizes": "(max-width: 613px) 100vw, 613px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b364d218dee0d1e858a37d1a1df4c66a/81c8e/IN-Lan_Power_Packet_Utility_07.png 230w", "/en/static/b364d218dee0d1e858a37d1a1df4c66a/08a84/IN-Lan_Power_Packet_Utility_07.png 460w", "/en/static/b364d218dee0d1e858a37d1a1df4c66a/5754a/IN-Lan_Power_Packet_Utility_07.png 613w"],
              "sizes": "(max-width: 613px) 100vw, 613px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b364d218dee0d1e858a37d1a1df4c66a/5754a/IN-Lan_Power_Packet_Utility_07.png",
              "alt": "IN-LAN 500 Power Packet Utility",
              "title": "IN-LAN 500 Power Packet Utility",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`[3]`}</strong>{`
You can now go to Privacy to change the encryption for your adapters. Now fill in the new password and press Set All Devices. By doing so all adapters will be set to the new encryption.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "615px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4c5e5ca748bd9cc153d544e8d9bc6f64/f6b72/IN-Lan_Power_Packet_Utility_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAACyklEQVQ4y5WSy24jVRRF/QcM0sz4ACYIIfENfAlS6AESQqIRUz4GJoCg6XQ6ne408duOXeWqW++36+Fy2VW2g8R4obrpNgliwmBp3yPd2nefc6rz0ZOKj59UfPJtxaffVXzw5Y5Hj/e8/3jHyWnNyRcNJ6fNncpzfeTRacN7n9d8+HXDZ9/v+eqb53Q0QyMKBY6tYppzhKGimxqGrbPMI5IsPBK/0zQgTHyipU+4vNOi8FFVlU6SGITBGNcZYJldLOOawBviuSOSYELiT4j9MWk4JQ1vZF3lGre1w35rc9ja7DY2fx08DEujk6c2xuXPzF78gtV9gTd8RSnGrJVrsnjBKjNIE404UIgChThUyBKdamVRlbZkvbI4NC6aUOnkiUl48RP2xW+sRm8ohlfkg9ekr59i6l0i/waxuGY8OGc8PJc66j/HMYc0G5ft2pbmt42Lbqh0styjyjRSb0JkDQjNPrE1IPYmpMmCRCZakCUaaazK87owKXODTWkfuWfocrsPZOwonBMGM6mBN5Xpwrfq2SNsoy/runIkbbqWzfq+YeYQ+VNC/0Z+mMYK+61/R+1zaIIH7LaeNGnbfceDhGnmsC4ERapj6F1M0SNwJ9jmQNbt/DTlzVHbhEUmcO0xnjPBs8dyHPcStq84xwFvSosyF+RLTV5uW3Xt0VGXsSI3mwQzluGcPFlQ5MbDhK1ha9K+7lhD5tNLpuMLFvMrZtNLBr0zbsYvGfbOsK0R+9kl7vmPmGc/kF3/yka54rDz/zGs29WXFqtMZ5UJad6e14VBHMxk67YxkEuJgjnV/BVh7ylB/xlx/3fKxR8cdh66eNtyLVt12FZt284D6splt/Vptp7Utt5sA+omPFJVHn82HpqxoJOmJnXZ/vmCzX9QrXSq4h4rnU3xL1aCZi3QxZzOVBEIQ0fTdTTxP9F1dCGYKSrdXpdnLwf8DVhYEE3EIoFQAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c5e5ca748bd9cc153d544e8d9bc6f64/e4706/IN-Lan_Power_Packet_Utility_08.avif 230w", "/en/static/4c5e5ca748bd9cc153d544e8d9bc6f64/d1af7/IN-Lan_Power_Packet_Utility_08.avif 460w", "/en/static/4c5e5ca748bd9cc153d544e8d9bc6f64/3b39a/IN-Lan_Power_Packet_Utility_08.avif 615w"],
              "sizes": "(max-width: 615px) 100vw, 615px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4c5e5ca748bd9cc153d544e8d9bc6f64/a0b58/IN-Lan_Power_Packet_Utility_08.webp 230w", "/en/static/4c5e5ca748bd9cc153d544e8d9bc6f64/bc10c/IN-Lan_Power_Packet_Utility_08.webp 460w", "/en/static/4c5e5ca748bd9cc153d544e8d9bc6f64/26ec8/IN-Lan_Power_Packet_Utility_08.webp 615w"],
              "sizes": "(max-width: 615px) 100vw, 615px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c5e5ca748bd9cc153d544e8d9bc6f64/81c8e/IN-Lan_Power_Packet_Utility_08.png 230w", "/en/static/4c5e5ca748bd9cc153d544e8d9bc6f64/08a84/IN-Lan_Power_Packet_Utility_08.png 460w", "/en/static/4c5e5ca748bd9cc153d544e8d9bc6f64/f6b72/IN-Lan_Power_Packet_Utility_08.png 615w"],
              "sizes": "(max-width: 615px) 100vw, 615px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4c5e5ca748bd9cc153d544e8d9bc6f64/f6b72/IN-Lan_Power_Packet_Utility_08.png",
              "alt": "IN-LAN 500 Power Packet Utility",
              "title": "IN-LAN 500 Power Packet Utility",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      